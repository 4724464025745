export enum ETelemetryActionsAndLabels {
  View = 'view',
  Click = 'click',
}

export enum ETelemetryEventNames {
  AccountDetails = 'AccountDetails',
  ErrorPage = 'ErrorPage',
}

export enum EPage {
  AccountDetails = 'accountDetails',
  ContactDetails = 'contactDetails',
}
