import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BaseTemplate, PageHead } from '@myob/myob-widgets';
import { ContactDetailsWrapper } from './styles';
import { ContactForm } from './components/ContactForm';
import { GetContactDetailsState, fetchContactDetailsAsync } from './reducers/getContact';
import { updateContactDetailsAsync, UpdateContactDetailsState, resetIsUpdateError } from './reducers/updateContact';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from 'stores';
import LoadingWrapper from 'components/LoadingWrapper';
import { EPage } from 'telemetry/type';
import ErrorComponent from 'components/ErrorComponent';
import ModalBox from '../../components/ModalComponent';
import { Notification } from '../../components/Notification';
import { isEmpty, omitBy } from 'lodash';
import { ContactDetailsInfo } from './type';
import { UpdateContactDetailsSchema } from 'helpers/api';
import { ENotificationType } from 'stores/reducers/notification/type';
import { identityTrack } from '../../helpers/tools';

const ContactDetails: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const formRef = useRef(null);
  const {
    data: contactInfo,
    isLoading,
    isError,
  } = useSelector<RootState, GetContactDetailsState>((state) => state.getContact);

  const { isError: isUpdateError } = useSelector<RootState, UpdateContactDetailsState>((state) => state.updateContact);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    dispatch(resetIsUpdateError());
    dispatch(fetchContactDetailsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (isUpdateError === false) {
      dispatch(fetchContactDetailsAsync()).then((res: Record<string, any>) => {
        const contactInfo = {
          firstName: res.payload.firstname,
          lastName: res.payload.lastname,
          emailAddress: res.payload.email,
        };
        identityTrack(contactInfo);
      });
      dispatch({
        type: ENotificationType.Success,
        primaryMessage: '<b>Congratulations.</b><p>Your changes have been saved successfully.</p>',
        dismissAfter: 24 * 3600 * 1000,
      });
    } else if (isUpdateError === true) {
      dispatch({
        type: ENotificationType.Danger,
        primaryMessage: '<b>Unfortunately we have encountered a problem.</b><p>Please try again later.</p>',
        dismissAfter: 24 * 3600 * 1000,
      });
    } else {
      dispatch({
        type: ENotificationType.Clear,
      });
    }
  }, [isUpdateError]);

  const handleOnDiscard = () => {
    dispatch(resetIsUpdateError());
    setShowModal(false);
    formRef.current.setIsEdit(false);
    dispatch(fetchContactDetailsAsync());
  };

  const handleGoBack = () => {
    setShowModal(false);
  };

  const updateContactDetails = (formData: ContactDetailsInfo) => {
    const data = { firstname: formData.firstname, lastname: formData.lastname, phone: formData.phone };
    const requestData = omitBy(data, isEmpty) as UpdateContactDetailsSchema;

    dispatch(resetIsUpdateError());
    dispatch(updateContactDetailsAsync(requestData));
  };

  const handleOnDismiss = () => {
    dispatch(resetIsUpdateError());
  };

  const content = useMemo(() => {
    if (isError) {
      return <ErrorComponent pageTitle="Contact details" pageName={EPage.ContactDetails} />;
    }
    return (
      <ContactDetailsWrapper>
        <PageHead title="Contact details" className="page-head" />
        <Notification onDismiss={handleOnDismiss} />
        {contactInfo && (
          <ContactForm
            data-testid="contact-details-form"
            contactInfo={contactInfo}
            onCancel={() => setShowModal(true)}
            ref={formRef}
            onSave={updateContactDetails}
          />
        )}
      </ContactDetailsWrapper>
    );
  }, [isError, contactInfo]);

  return (
    <div data-testid="contact-details-page">
      {showModal && (
        <ModalBox
          onDiscard={handleOnDiscard}
          onGoBack={handleGoBack}
          title="Discard unsaved changes?"
          message="You've made changes that will be lost if you don't go back and save them."
        />
      )}
      <LoadingWrapper isLoading={isLoading}>
        <BaseTemplate>{content}</BaseTemplate>
      </LoadingWrapper>
    </div>
  );
};

export default ContactDetails;
