import { telemetry } from '@my-account/tools';
import queryString from 'query-string';
import isArray from 'lodash/isArray';

export function getParameterByName(name: string, searchString?: string): string | null {
  if (!searchString) {
    searchString = '?' + window.location.href.split('?')[1];
  }
  const value = queryString.parse(searchString, { decode: false })[name] || null;
  if (isArray(value)) {
    return value[0];
  } else {
    return value;
  }
}

export function getBusinessId(): string {
  return getParameterByName('businessId') || localStorage.getItem('businessId') || '';
}

export function saveBusinessId(): void {
  const businessId = getParameterByName('businessId');

  if (businessId) {
    localStorage.setItem('businessId', businessId);
  }
  const existingBusinessId = getBusinessId();
  if (existingBusinessId !== '') {
    telemetry.initBusinessId().setBusinessId(existingBusinessId);
  }
}
