import { auth, GtmManager } from '@my-account/tools';
import config from 'config';
import { gaPushEvent } from './googleAnalytics';

export interface ResponseError {
  status?: HttpStatus;
  message: string;
}

export enum HttpStatus {
  BadRequest = 400,
  Forbidden = 403,
  Unauthorized = 401,
  ServerError = 500,
}

export const getRequestUrl = (requestPath: string): string => `${config.API_BASE_URL}${requestPath}`;

function handle5xxError(status: number, requestPath: string, reject: (reason?: any) => void) {
  if (status >= HttpStatus.ServerError) {
    const apiPath = requestPath.split('?')[0];
    gaPushEvent({
      event: 'APIError',
      httpCode: status,
      apiUrl: apiPath,
    });
    reject({ status, message: 'Server Error' } as ResponseError);
  }
}

function handle4xxError(status: number, body, requestPath: string, reject: (reason?: any) => void) {
  if (status >= HttpStatus.BadRequest && status < HttpStatus.ServerError) {
    const { message, error, path } = body;
    const apiPath = path || requestPath.split('?')[0];
    gaPushEvent({
      event: 'APIError',
      errorId: error,
      errorText: message,
      httpCode: status,
      apiUrl: apiPath,
    });
    reject({ status, message } as ResponseError);
  }
}

function pushGTMEvent(httpMethod: string, apiPath: string, status: number) {
  GtmManager.dataLayer({
    dataLayer: {
      event: 'API_Request',
      api_url: `${httpMethod} ${apiPath}`,
      http_status: status,
    },
    dataLayerName: 'MyAccount',
  });
}

function buildHttpMethodAndHeaders(customConfig: RequestInit, token: string) {
  const config: RequestInit = {
    method: 'GET',
    ...customConfig,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  };
  return config;
}

export const request = async <T>(requestPath: string, customConfig?: RequestInit): Promise<T> => {
  const token = await auth.getToken();

  return new Promise<T>((resolve, reject) => {
    const config = buildHttpMethodAndHeaders(customConfig, token);
    const requestUrl = getRequestUrl(requestPath);
    const apiPath = requestUrl.split('?')[0];

    fetch(requestUrl, config)
      .then(async (response) => {
        const { status } = response;
        pushGTMEvent(config.method, apiPath, status);

        if (status === HttpStatus.Unauthorized) {
          return auth.login();
        }
        handle5xxError(status, requestPath, reject);

        const body = config.method === 'PUT' && status === 200 ? null : await response.json();

        handle4xxError(status, body, requestPath, reject);

        resolve(body);
      })
      .catch(reject);
  });
};
