import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Route, Switch } from 'react-router-dom';
import AccountDetails from 'modules/AccountDetails';
import ContactDetails from 'modules/ContactDetails';
import { usePageView } from 'hooks/usePageView';
import { AccountSelectWrapper } from '@my-account/account';

export enum InternalRoutePath {
  ManageAccountAccountDetails = '/account/manage-account/account-details',
  ContactDetails = '/account/contact-details',
}

export const InternalRoutePageNameMap = new Map([
  [InternalRoutePath.ManageAccountAccountDetails, 'Account Details'],
  [InternalRoutePath.ContactDetails, 'Contact Details'],
]);

export const ERoutePath = {
  ...InternalRoutePath,
};

export const CONTACT_SUPPORT_ROUTE = '/account/support/contact-support';

const Routes: React.FC = () => {
  usePageView();
  return (
    <CacheProvider
      value={createCache({
        key: 'my-account-account-details',
      })}
    >
      <Switch>
        <Route exact path={ERoutePath.ManageAccountAccountDetails}>
          <AccountSelectWrapper>
            <AccountDetails />
          </AccountSelectWrapper>
        </Route>
        <Route exact path={ERoutePath.ContactDetails}>
          <ContactDetails />
        </Route>
      </Switch>
    </CacheProvider>
  );
};

export default Routes;
